import { AuthService as auth } from "../Services/AuthService";
import { ApiService as api } from "../Services/APIService";
import { TemplateService } from "../Services/TemplateService";
import { UtilService as util } from "../Services/UtilService";

const groupCardTemplate = 'GroupCard';
const groupModalTemplate = 'GroupModal';


export class UserProfileModule {
    static async Init() {
        DEBUG && console.log('UserProfile Constructor Fired...');

        const element = document.getElementById('userProfileModal');
        let self = this;

        element.addEventListener('show.bs.modal', (evt) => {
            DEBUG && console.log('===> Profile Modal Show');
            self.openProfileModal();
        });
    }

    static openProfileModal() {
        DEBUG && console.log('===> Open Profile Modal');

        // Check Authentication
        auth.checkAuthenticated();


        // Gather Elements
        const modalProfileTitle = document.getElementById('modalProfileTitle');
        const modalProfileBodyContent = document.getElementById('userProfile-profile');

        // Get Data
        api.getAuthenticatedData(`User/GetUserProfile`)
            .then(data => {
                var content = TemplateService.GetRenderedTemplateSync("UserProfile", data);
                modalProfileBodyContent.innerHTML = content;

                // Init Form Actions
                this.InitProfileForm();

                // Init File Upload
                this.InitFileUpload();

                // Init Change Password Form
                this.InitPasswordForm();
            });
        
        // Replace Modal Header
        modalProfileTitle.innerText = `My User Profile`;        
    }

    static InitFileUpload() {
        const profilePhoto = document.getElementById("profilePhoto");
        let self = this;
        profilePhoto.addEventListener("change", function (event) {
            const selectedFile = event.target.files[0];

            if (selectedFile) {

                if (selectedFile.type.startsWith('image/')) {
                    const reader = new FileReader();
                    const profileImage = document.getElementById("myProfileImage");
                    reader.onload = function (e) {
                        profileImage.src = e.target.result;
                    }

                    reader.readAsDataURL(selectedFile);

                    self.uploadFile(selectedFile);
                }
            }
        });
    }

    static uploadFile(file) {
        console.log(file);

        var formData = new FormData();
        formData.append('file', file);

        api.postApiData('User/UploadContactImage', formData, null, true, true)
            .then((data) => {
                util.showSuccessToast('Photo Updated', 5000, true);
                // Reload Profile

            })
            .catch((error) => {
                console.error('Error:', error);
                util.showErrorToast('An error occured while uploading your photo.', 20000, true);
            });


    }

    static InitPasswordForm() {
        var formChangePassword = document.getElementById("formUserPassword");

        let self = this;

        util.formSetup(formChangePassword);

        formChangePassword.addEventListener('submit', function (event) {

            event.preventDefault();
            event.stopPropagation();

            // Check Validity
            if (!formChangePassword.checkValidity()) {
                console.info("*** => Form INVALID");
            }
            else {
                // Submit Form
                console.log('Submitting Form');
                self.submitUpdatePassword(formChangePassword);
            }

            formChangePassword.classList.add('was-validated')
        }, false)   
    }

    static InitProfileForm() {

        var profileForm = document.getElementById("formModalProfile");

        let self = this;

        util.formSetup(profileForm);

        profileForm.addEventListener('submit', function (event) {

            event.preventDefault();
            event.stopPropagation();

            // Check Validity
            if (!profileForm.checkValidity()) {
                console.info("*** => Form INVALID");
            }
            else {
                // Get Captcha Token
                grecaptcha.ready(function () {
                    grecaptcha.execute('6LflXjcpAAAAALr80wVEcMlED_FNjFM4_UP3oa9J', { action: 'submit' })
                        .then(function (token) {
                            DEBUG && console.log("Captcha Output");
                            DEBUG && console.log(token);

                            // Submit Form via AJAX!!
                            self.submitProfileForm(token, profileForm);
                        });
                });
            }

            profileForm.classList.add('was-validated')
        }, false)     
    }

    static submitProfileForm(token, formElement) {
        DEBUG && console.log("***==> Send Profile Data");

        let self = this;

        var formData = util.getFormDataObject(formElement);

        api.postApiData(`User/UpdateProfile`, formData, token)
            .then((data) => {
                util.showSuccessToast('Profile Updated', 7000, true);
                util.closeModal(`userProfileModal`);
            })
            .catch((error) => {
                console.error('Error:', error);
                util.showErrorToast('An error occured while saving your information.', 20000, true);
            });
    }

    static async submitUpdatePassword(formElement) {
        // Get the element by its ID
        const passwordAlerts = document.getElementById('passwordAlerts');
        if (passwordAlerts) {
            // Add the Bootstrap alert classes
            passwordAlerts.classList.remove('alert', 'alert-danger', 'text-white', 'text-bold', 'alert-success');
            passwordAlerts.innerText = "";
        }

        try {
            DEBUG && console.log("***==> Send Password Update Data");
            const formData = util.getFormDataObject(formElement);
            const response = await api.postApiDataAsync('User/UpdateUserPassword', formData, null, false, false, true);

            console.log("Password updated successfully:", response);
            util.showSuccessToast('Password updated successfully', 5000, true);

            if (passwordAlerts) {
                // Add the Bootstrap alert classes
                passwordAlerts.classList.add('alert', 'alert-success', 'text-white', 'text-bold');
                passwordAlerts.innerText = "Your password was successfully updated.";
            }
        } catch (error) {
            // Handle the error based on its structure
            let errorMessage = "Error updating password.";
            console.error(errorMessage);

            // Extract the JSON substring
            const start = error.error.indexOf('{');
            const end = error.error.lastIndexOf('}') + 1;
            const jsonStr = error.error.substring(start, end);

            // Parse JSON and extract the Message value
            const errorData = JSON.parse(jsonStr);
            console.log(errorData.Message);

            if (errorData.Message == "Invalid user identifier or old password. The password was not changed.") {

            }



            // Make sure the element exists before applying changes
            if (passwordAlerts) {
                // Add the Bootstrap alert classes
                passwordAlerts.classList.add('alert', 'alert-danger', 'text-white', 'text-bold');

                // Check if errorData exists and has a message property (using lower-case if needed)
                if (errorData && (errorData.message || errorData.Message)) {
                    // Use the appropriate property from errorData
                    passwordAlerts.innerText = errorData.message || errorData.Message;
                } else {
                    // Fallback message if errorData is not defined or doesn't contain a message
                    passwordAlerts.innerText = "An unknown error occurred.";
                }
            }

            util.showErrorToast(errorData.Message, 10000, true);
        }
    }
}