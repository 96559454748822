import { UserProfileModule } from "../Modules/UserProfileModule";

window.addEventListener('load', function () {
    UserProfileModule.Init();
});

document.addEventListener('widgetLoaded', function (args) {
    console.log(args);
    if (args.detail.model.Name === "UserProfile") {
        DEBUG && console.info('**************************************************');
        DEBUG && console.info('=== Widget Reload Event ===')
        DEBUG && console.info('Element: ', args.detail.element);
        DEBUG && console.info('**************************************************');
        UserProfileModule.Init();
    }
});