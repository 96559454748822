import { AuthService as auth } from "../Services/AuthService";
import { ApiService as api } from "../Services/APIService";
import { TemplateService } from "../Services/TemplateService";
import { UtilService as util } from "../Services/UtilService";

const groupCardTemplate = 'GroupCard';
const groupModalTemplate = 'GroupModal';


export class UserProfileModule {
    static async Init() {
        DEBUG && console.log('UserProfile Constructor Fired...');

        const element = document.getElementById('userProfileModal');
        let self = this;

        element.addEventListener('show.bs.modal', (evt) => {
            DEBUG && console.log('===> Profile Modal Show');
            self.openProfileModal();
        });
    }

    static openProfileModal() {
        DEBUG && console.log('===> Open Profile Modal');

        // Check Authentication
        auth.checkAuthenticated();


        // Gather Elements
        const modalProfileTitle = document.getElementById('modalProfileTitle');
        const modalProfileBodyContent = document.getElementById('userProfile-profile');

        // Get Data
        api.getAuthenticatedData(`User/GetUserProfile`)
            .then(data => {
                var content = TemplateService.GetRenderedTemplateSync("UserProfile", data);
                modalProfileBodyContent.innerHTML = content;

                // Init Form Actions
                this.InitProfileForm();

                // Init File Upload
                this.InitFileUpload();
            });
        
        // Replace Modal Header
        modalProfileTitle.innerText = `My User Profile`;        
    }

    static InitFileUpload() {
        const profilePhoto = document.getElementById("profilePhoto");
        let self = this;
        profilePhoto.addEventListener("change", function (event) {
            const selectedFile = event.target.files[0];

            if (selectedFile) {

                if (selectedFile.type.startsWith('image/')) {
                    const reader = new FileReader();
                    const profileImage = document.getElementById("myProfileImage");
                    reader.onload = function (e) {
                        profileImage.src = e.target.result;
                    }

                    reader.readAsDataURL(selectedFile);

                    self.uploadFile(selectedFile);
                }
            }
        });
    }

    static uploadFile(file) {
        console.log(file);

        var formData = new FormData();
        formData.append('file', file);

        api.postApiData('User/UploadContactImage', formData, null, true, true)
            .then((data) => {
                util.showSuccessToast('Photo Updated', 5000, true);
                // Reload Profile

            })
            .catch((error) => {
                console.error('Error:', error);
                util.showErrorToast('An error occured while uploading your photo.', 20000, true);
            });


    }

    static InitProfileForm() {

        var profileForm = document.getElementById("formModalProfile");

        let self = this;

        util.formSetup(profileForm);

        profileForm.addEventListener('submit', function (event) {

            event.preventDefault();
            event.stopPropagation();

            // Check Validity
            if (!profileForm.checkValidity()) {
                console.info("*** => Form INVALID");
            }
            else {
                // Get Captcha Token
                grecaptcha.ready(function () {
                    grecaptcha.execute('6LflXjcpAAAAALr80wVEcMlED_FNjFM4_UP3oa9J', { action: 'submit' })
                        .then(function (token) {
                            DEBUG && console.log("Captcha Output");
                            DEBUG && console.log(token);

                            // Submit Form via AJAX!!
                            self.submitProfileForm(token, profileForm);
                        });
                });
            }

            profileForm.classList.add('was-validated')
        }, false)     
    }

    static submitProfileForm(token, formElement) {
        DEBUG && console.log("***==> Send Profile Data");

        let self = this;

        var formData = util.getFormDataObject(formElement);

        api.postApiData(`User/UpdateProfile`, formData, token)
            .then((data) => {
                util.showSuccessToast('Profile Updated', 7000, true);
                util.closeModal(`userProfileModal`);
            })
            .catch((error) => {
                console.error('Error:', error);
                util.showErrorToast('An error occured while saving your information.', 20000, true);
            });
    }
}